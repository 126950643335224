import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=069c1406&scoped=true"
import script from "./Tabs.vue?vue&type=script&lang=js"
export * from "./Tabs.vue?vue&type=script&lang=js"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=069c1406&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069c1406",
  null
  
)

export default component.exports