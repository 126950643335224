<template>
    <svg :width="size" :height="size + 2" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3C3 1.34315 4.34315 0 6 0H17C18.6569 0 20 1.34315 20 3V16C20 17.6569 18.6569 19 17 19H6C4.34315 19 3 17.6569 3 16V3Z" fill="#498EF6"/>
        <path d="M0 6C0 4.34315 1.34315 3 3 3H14C15.6569 3 17 4.34315 17 6V19C17 20.6569 15.6569 22 14 22H3C1.34315 22 0 20.6569 0 19V6Z" fill="#67A7F9"/>
        <path d="M1.11115 9.11115H6.76241V10.0745H4.50191V15.9896H3.37165V10.0745H1.11115V9.11115Z" fill="white"/>
        <path d="M7.5858 9.11115H8.90925L11.2567 14.506H11.2953L13.6331 9.11115H14.9566V15.9896H13.8263V11.2498H13.7877L11.759 15.9896H10.7833L8.75469 11.2498H8.71605V15.9896H7.5858V9.11115Z" fill="white"/>    
    </svg>

</template>
<script>
export default {
    name: 'AssetMemory',
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
