<template>
    <svg :width="size" :height="size + 2" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3C3 1.34315 4.34315 0 6 0H17C18.6569 0 20 1.34315 20 3V16C20 17.6569 18.6569 19 17 19H6C4.34315 19 3 17.6569 3 16V3Z" fill="#1AC49C"/>
        <path d="M0 6C0 4.34315 1.34315 3 3 3H14C15.6569 3 17 4.34315 17 6V19C17 20.6569 15.6569 22 14 22H3C1.34315 22 0 20.6569 0 19V6Z" fill="#7DD6B3"/>
        <path d="M1.12839 8.12663V9.22612H3.70121V15.977H4.98762V9.22612H7.56043V8.12663H1.12839Z" fill="white"/>
        <path d="M8.49758 8.12663V15.977H12.1039C12.9395 15.977 13.5992 15.8231 14.061 15.5152C14.5998 15.1414 14.8747 14.5587 14.8747 13.767C14.8747 13.2393 14.7427 12.8105 14.4898 12.5026C14.226 12.1838 13.8411 11.9749 13.3244 11.8759C13.7202 11.722 14.0171 11.5131 14.226 11.2272C14.4349 10.9193 14.5448 10.5455 14.5448 10.1057C14.5448 9.51199 14.3359 9.03921 13.9291 8.68737C13.4893 8.31354 12.8736 8.12663 12.0929 8.12663H8.49758ZM9.78399 9.18214H11.7741C12.3018 9.18214 12.6757 9.2701 12.9176 9.44602C13.1375 9.61095 13.2584 9.88582 13.2584 10.2596C13.2584 10.6665 13.1375 10.9633 12.9176 11.1502C12.6867 11.3262 12.3018 11.4251 11.7521 11.4251H9.78399V9.18214ZM9.78399 12.4806H11.95C12.5217 12.4806 12.9395 12.5796 13.2034 12.7775C13.4563 12.9754 13.5882 13.2943 13.5882 13.745C13.5882 14.1848 13.4123 14.4927 13.0605 14.6906C12.7856 14.8445 12.4008 14.9215 11.917 14.9215H9.78399V12.4806Z" fill="white"/>
    </svg>
</template>
<script>
export default {
    name: 'AssetTerm',
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
